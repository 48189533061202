/** @jsx jsx */
import { jsx } from '@emotion/core';
import style from './style';
import utils from '../../../utils';
import { Loading, ErrorMessage } from '../../../components';
import env from '../../../env';
import { useEffect, useState, Fragment, useRef } from 'react';
import Slider from "react-slick";

export function EnterTip({tippingSession, logOUt}){

  const [sendingStatus, setSendingStatus] = useState('pending');
  const [compRounds, setCompRounds] = useState();
  //const [currentRound, setCurrentRound] = useState({index : 0, roundId : null});
  const [currentRound, setCurrentRound] = useState({index : null, roundId : null});
  const [buttonEnable, setButtonEnable] = useState(0);
  const [games, setGames] = useState();
  const [tipGames, setTipGames] = useState();
  const [errors, setErrors] = useState();
  const [enterTipMsg, setEnterTipMsg] = useState();
  const [compRoundId, setCompRoundId] = useState();
  const _slider = useRef(null);

  useEffect(() => {

    if(!sendingStatus || sendingStatus !== "pending"){
      return;
    }

    const abortController = new AbortController();
    const signal = abortController.signal;
    let timer1 = null;
    const fetchData = async () => {
      //const value = { token : tippingSession, roundId : roundId};
      const value = { token : tippingSession, roundId : currentRound.roundId };
      const res = await fetch(env.apiBase + "/api/proxy/tippinggetenter", {
        method : 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(value),
        signal : signal
      });
      const _data = await res.json();
      if(_data.errors && _data.errors.length > 0){
        let checkSessionExpired = false;
        const _errors = [];
        for(let i in _data.errors){
          if(_data.errors[i].errorMessage === "session expired"){
            checkSessionExpired = true;
          }
          _errors.push(_data.errors[i].errorMessage);
        }
        setErrors(_errors);
        setSendingStatus(null);
        if(checkSessionExpired === true){
          logOUt();
        }
        return;
      }
      
      console.log("_result_", _data);
      if(_data.results.tippingEnterInfo && _data.results.tippingEnterInfo.Result.info){
        const _games = _data.results.tippingEnterInfo.Result.info.Games;
        const _gamesSaNfl = []; 
        const _gamesSaNflDate = [];
        const _gamesAfl = [];
        const _gamesAflDate = [];
        var checkDate = "";
        var checkDate2 = "";
        var buttonEnableCount = 0;
        var _setTipGames = [];

        _games.forEach(el => {
          if(el.League === "SANFL"){
            _gamesSaNfl.push(el);
            if(checkDate !== el.GameDateTextDay){
              _gamesSaNflDate.push(el.GameDateTextDay);
              checkDate = el.GameDateTextDay;
            }
          }
          else if(el.League === "AFL"){
            _gamesAfl.push(el);
            if(checkDate2 !== el.GameDateTextDay){
              _gamesAflDate.push(el.GameDateTextDay);
              checkDate2 = el.GameDateTextDay;
            }
          }

          if(el.CanEnter === true){
            buttonEnableCount ++;
          }
          _setTipGames.push({
            id : el.GameId,
            value : el.UserTip.WinningTeamId === 0 ? "" : el.UserTip.WinningTeamId,
            margin : el.UserTip.Margin === 0 ? "" : el.UserTip.Margin, 
          });
        });

        if(!compRounds){
          setCompRounds(_data.results.tippingEnterInfo.Result.info.CompRounds);
        }
        setCompRoundId(_data.results.tippingEnterInfo.Result.info.CompRoundId);
        
        setGames({sanfl : _gamesSaNfl, sanflDate : _gamesSaNflDate, afl : _gamesAfl, aflDate : _gamesAflDate});
        setTipGames(_setTipGames);
        setButtonEnable(buttonEnableCount);

        if(!currentRound.index){
          if(_gamesSaNfl.length > 0){
            setCurrentRound({index : (_gamesSaNfl[0].CompRound.SortingOrder) -1, roundId : _gamesSaNfl[0].CompRound.CompRoundId});
          }
        }

        timer1 = setTimeout(() => {
          setSendingStatus(null);
          //if(_slider){
            //console.log("_slider.slickGoTo(4);", _slider);
            //_slider.current.slickNext();
          //}
          _slider.current.slickGoTo(19);
        }, 100);

      }
    }
    
    console.log("_compRound", compRounds);

    fetchData();
    return () => {
      abortController.abort();
      if(timer1){
        clearTimeout(timer1);
      }
    }

    // eslint-disable-next-line
  }, [sendingStatus]);

  useEffect(() => {
    
    if(!sendingStatus || sendingStatus !== "pending2"){
      return;
    }
    let timer1 = null;
    const fetchData = async () => {
      const value = { token : tippingSession, tipGames : tipGames, compRoundId : compRoundId };
      const res = await fetch(env.apiBase + "/api/proxy/tippingentertip", {
        method : 'post',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(value)
      });
      
      const _data = await res.json();

      if(_data.errors && _data.errors.length > 0){
        let checkSessionExpired = false;
        const _errors = [];
        for(let i in _data.errors){
          if(_data.errors[i].errorMessage === "session expired"){
            checkSessionExpired = true;
          }
          _errors.push(_data.errors[i].errorMessage);
        }
        setErrors(_errors);
        setSendingStatus(null);
        if(checkSessionExpired === true){
          logOUt();
        }
        return;
      }

      setSendingStatus(null);
      setEnterTipMsg("Successfully entered tips.");
      timer1 = setTimeout(() => {  
        setEnterTipMsg(null);
      }, 3000);

    };

    fetchData();
    return () => {
      if(timer1){
        clearTimeout(timer1);
      }
    }

    // eslint-disable-next-line
  }, [sendingStatus]);

  var settings = {
    dots: false,
    infinite: false,
    //speed: 1700,
    speed: 500,
    autoplaySpeed: 3000,
    autoplay: false,
    slidesToShow: (compRounds && compRounds.length) >=18 ? 18 : (compRounds && compRounds.length) ? compRounds.length : 0,
    slidesToScroll: 1,
    responsive : [
        {breakpoint : 800, settings : { slidesToShow: 6 }}
    ]
  };

  function getGames(date, type){
    if(type === "sanfl"){
      const result = games.sanfl.filter(x => x.GameDateTextDay.includes(date)); 
      const list = result.map((item, index) => 
        <GameView key={index} game = {item} index = {index} addTipGame = {addTipGame}></GameView>
      );
      return list;
    }
    else if(type === "afl"){
      const result = games.afl.filter(x => x.GameDateTextDay.includes(date)); 
      const list = result.map((item, index) => 
        <GameView key={index} game = {item} index = {index} addTipGame = {addTipGame}></GameView>
      );
      return list;
    }
  }

  function selectRound (e, index, roundId){
    setCurrentRound({index : index, roundId : roundId});
    setSendingStatus('pending');
  }

  const onSubmit = ((e) => {
    if(tipGames.length > 0){
      setErrors(null);
      setEnterTipMsg(null);
      setSendingStatus('pending2');
    }
  });

  const addTipGame = (tip) => {
    setTipGames(pre => {
      let _new = [...pre];
       for(let i in _new){
        if(_new[i].id === tip.id){
          _new[i].value = tip.value;
          //_new[i].margin = tip.margin === "" ? null : tip.margin;
          _new[i].margin = tip.margin;
          break;
        }
      }
      return _new;
    });
    // setTipGames([...tipGames, tip]);
  };

  return( 
    <Fragment>
      {
          compRounds &&
          <Fragment>
            <h4>Select Round</h4>
            <div css = {style.round__container} >
            <Slider {...settings} ref={_slider}>
            {
              compRounds.map((item, index) => 
                <div css = {style.round__text} key={index}>
                  <div className={`value ${currentRound.index === index && 'selected'}` } onClick={(e) => { 
                    if(currentRound.index !== index){
                      selectRound(e, index, item.CompRoundId);
                    }
                  }}>
                    { item.CompRoundName.replace("Round", "").trim() }
                  </div>
                </div>
              )
            }
            </Slider>
          </div>
          </Fragment>
          // <SelectRoundView compRounds = {compRounds} selectRound = {selectRound} currentRound = {currentRound} />
        }
      {
        sendingStatus === 'pending' ?
        <Loading></Loading> :
        <div style = {{marginTop: '1rem'}}>
        {
          (games && games.sanflDate.length) > 0 && 
          <div css = {style.games__container}>
              <div>SANFL</div>
            {
              games.sanflDate.map((item, index) => 
                // <GameView key = {index} game = {item}></GameView>
                <div key={index} css={style.games}>
                  <div css={style.game__date}>
                    { item }
                  </div>
                  { getGames(item, "sanfl") }
                </div>
              )
            }
          </div>
        }

        {/* {
          (games && games.aflDate.length) > 0 && 
          <div css = {style.games__container} className="afl">
              <div>AFL</div>
            {
              games.aflDate.map((item, index) => 
                // <GameView key = {index} game = {item}></GameView>
                <div key={index} css={style.games}>
                  <div css={style.game__date}>
                    { item }
                  </div>
                  { getGames(item, "afl") }
                </div>
              )
            }
          </div>
        } */}

        {
          buttonEnable > 0 &&
          
          <div style={{textAlign:'center'}}>
            <button className="btn btn-primary" onClick={onSubmit}> Enter Tip </button>
          </div>
        }

        <div style={{textAlign : 'center', marginTop:'0.5rem'}}>
          <ErrorMessage errors={errors} />

          { enterTipMsg &&
            <span style={{marginLeft: '1.5rem', color:'red', fontWeight: 'bold'}}>
              { enterTipMsg }
          </span>}

        </div>

    </div>
      }
    </Fragment>
  )
}

function GameView({game, index, addTipGame}){
  const [tipGame, setTipGame] = useState({id : game.GameId, value : game.UserTip.WinningTeamId, margin : game.UserTip.Margin === 0 ? "" : game.UserTip.Margin });
  const handChange = (el) => {
    if(!tipGame){ return; }    
    const elName = el.target.name;
    const elValue = el.target.value;

    setTipGame(pre => {
      let _new = {...pre};
      if(elName.includes("tip")){ 
        _new.value = elValue;
      }
      else{
        _new.margin = elValue;
      }
      setTimeout(() => {
        addTipGame(_new);    
      }, 500);
      return _new;
    });
  }

  return(
    <div css = {style.row} className={`row ${index % 2 === 0 ? "even" : "odd"}`}>
      <div className = "col-12" css = {style.venue}>
        {game.Venue + ", " + (game.GameDateTextTime === "12.00 AM" ? "TBC" : game.GameDateTextTime) }
      </div>
      <div className="col-6 col-md-5">
        <div css={style.logo} style={{backgroundImage: utils.css.bgUrlStyle(game.HomeTeam.TeamImage)}}></div>
        <div css={style.teamName}>
          <input type='radio' defaultChecked = {tipGame.value === game.HomeTeam.TeamId}  id={ "tip" + game.GameId + "01"} name = {"tip" + game.GameId} value = {game.HomeTeam.TeamId} disabled = {game.CanEnter === false} onChange={handChange} />
          <label style={{marginLeft: '0.3rem'}} htmlFor = { "tip" + game.GameId + "01"}>{game.HomeTeam.TeamName}</label>
          <span css = {style.homeAway}> | HOME </span>
        </div>
      </div>
      <div className="col-6 col-md-5">
        <div css={style.logo} style={{backgroundImage: utils.css.bgUrlStyle(game.AwayTeam.TeamImage)}}></div>
        <div css={style.teamName}>
          <input type='radio' defaultChecked = {tipGame.value === game.AwayTeam.TeamId} id={ "tip" + game.GameId + "02"} name = {"tip" + game.GameId} value = {game.AwayTeam.TeamId} disabled = {game.CanEnter === false} onChange={handChange}/>
          <label style={{marginLeft: '0.3rem'}} htmlFor = { "tip" + game.GameId + "02"}>{game.AwayTeam.TeamName}</label>
          <span css = {style.homeAway}> | AWAY </span>
        </div>
      </div>
      <div className="col-12 col-md-2" style={{margin:'auto',textAlign:'center'}}> 
        <input type='radio' defaultChecked = {tipGame.value === "0"} id={ "tip" + game.GameId + "03"} name = {"tip" + game.GameId} value = "0" disabled = {game.CanEnter === false} onChange={handChange} />
        <label htmlFor = { "tip" + game.GameId + "03"}> Draw </label> 
      </div>
      {
        (game.EnterMargin && game.EnterMargin === true) &&
        <div className="col-12" css = {style.marginContainer}>
          Margin : 
          <input type = "number" value={tipGame.margin} name = {"margin" + game.GameId} style={{width: '70px', marginLeft: '0.2rem', borderRadius:'5px'}} disabled = {game.CanEnter === false} onChange={handChange} />
        </div>
      }
    </div>
  )
}